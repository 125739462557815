@import '../partials/variables';

.slideshow-container {
  max-width: 60%; /* Reduce the width for more whitespace on the sides */
  margin: 0 auto; /* This will center the slideshow horizontally */
}

.slick-slide img {
  width: 100%; 
  max-height: 600px; 
  object-fit: contain;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.slick-dots li button:before {
  color: #fff; /* Change color to white or another contrasting color */
  font-size: 10px; /* Increase the size of the dots */
  border: 2px solid #aaa; /* Add a border to make them stand out more */
  border-radius: 50%; /* Make the dots perfectly circular */
  opacity: 0.8; /* You can adjust this for desired visibility */ 
}

/* For the active dot */
.slick-dots li.slick-active button:before {
  color: #333; /* Dark color for the active dot for contrast */
  border-color: #333; /* Dark border for the active dot */
}