@import '../partials/variables';

.TitleCard {
    display: flex;
    align-items: center;
    justify-content: center; /* Center content vertically and horizontally */
    background-color: #F0F5F9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    width: 250px;
    height: 350px;
    position: relative;
    margin: 10px; /* To ensure spacing between cards */
}

.TitleCard:hover {
    transform: scale(1.05);
}

.category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-title {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 7px 12px;
    border-radius: 6px;
    color: #1E2022;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
