@import '../partials/variables';

.about-container {
    font-family: 'IBM Plex Sans', sans-serif;
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #FAFAFA;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.about-container h1 {
    font-weight: 700;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: color-text-primary;
}

.about-container p {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: color-text-primary;
}
