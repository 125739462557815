@import '../partials/variables';

.type-motorcycle-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.type-motorcycle-header {
  text-align: center;
  margin-bottom: 2em;
  color: $color-text-primary;
  font-size: 2em;
}

.cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; // This will make sure that the cards are evenly spaced
  gap: 20px; // Handles spacing between cards
}

.card-wrapper {
  transition: 0.3s;
  flex: 0 0 calc((100% - 60px) / 3); // 3 cards per row, not allowed to grow
  min-width: calc((100% - 60px) / 3); // Minimum width for the card
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .card-wrapper {
    flex: 0 0 calc((100% - 40px) / 2); // 2 cards per row, not allowed to grow
    min-width: calc((100% - 40px) / 2);
  }
}

@media (max-width: 800px) {
  .card-wrapper {
    flex: 0 0 calc(100% - 20px); // 1 card per row, not allowed to grow
    min-width: calc(100% - 20px);
  }
}