@import '../partials/variables';

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
  background: #fff;
  max-width: 100%; /* Allow the card to fill the flex container */
  font-family: 'IBM Plex Sans', sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.image-container {
  width: 100%; /* this will take the full width of the parent element */
  padding-top: 75%; /* 3/4 = 75% aspect ratio for height */
  position: relative; /* allows absolute positioning inside */
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* this ensures the image covers the area without distorting */
}

.product-price {
  font-size: 1.2em;
  color: #333;
  margin-right: 16px; /* Added space between price and quantity box */
}

.quantity-box {
  padding: 2px 4px; /* Reduced padding to decrease height */
  font-size: 0.9em; /* Optional: Decrease font size if the input looks too large */
  line-height: 1.2; /* Optional: Adjust line height for better visual alignment */
  height: auto;
  display: flex;
  box-sizing: border-box; /* Include padding in the height calculation */
}

/* Modify the product-stats-wrapper for less vertical spacing */
.product-stats-wrapper {
  display: flex;
  justify-content: space-between; /* Align items to the start */
  align-items: center; /* Center items vertically */
  padding: 0; /* Keep padding to 0 */
  margin-top: 5px; /* Reduce margin-top to bring elements closer */
  gap: 8px; /* Provide some horizontal spacing between elements */
}


.card-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px; /* Reduced margin on top */
  flex-wrap: wrap;
}

.add-to-cart,
.view-btn {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  flex: 1 1 auto; /* Allows the buttons to grow and shrink as needed */
  flex-basis: calc(50% - 4px); /* Adjusted for the gap */
  flex-grow: 1; /* Allow buttons to grow to fill the space */
}

