@import '../partials/variables';

.gallery-cta {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px 0 0 0;  /* Adjusted padding for more vertical space */
}

.gallery-cta h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  color: color-text-primary;
  margin-bottom: 15px;
  margin-top: 0;   /* Remove the default margin at the top */
}

.gallery-cta p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: color-text-primary;
  max-width: 800px;
  margin: 0 auto; /* Center the text if it's less than the max-width */
}


.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 80px 0;
}

.category-box {
  background-color: #F0F5F9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  height: 350px; /* Increased height for rectangular images */
  position: relative;
}

.category-box:hover {
  transform: scale(1.05);
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #1E2022;
  height: 100%;
}

.category-image {
  width: 100%;
  height: 100%; /* Ensure image covers entire box */
  object-fit: cover;
}

.category-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* Lighter background for better readability */
  padding: 7px 12px; /* Slightly increased padding */
  border-radius: 6px;
  color: #1E2022;
  font-size: 20px; /* Larger font size for older audience */
  font-family: 'Roboto', sans-serif; /* Using Arial for a clean, sans-serif look */
  font-weight: 500; /* Bold font weight for better readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Text shadow for additional readability */
}


.gallery-learn-more {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px 0;  /* Adjusted padding for more vertical space */
}

.gallery-learn-more h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  color: color-text-primary;
  margin-bottom: 15px;
  margin-top: 0;   /* Remove the default margin at the top */
}

.gallery-learn-more p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: color-text-primary;
  max-width: 800px;
  margin: 0 auto; /* Center the text if it's less than the max-width */
}

.right-aligned img {
  float: right;
  margin-left: 20px; /* Add some space between the text and the image */
}

/* For left-aligned images */
.left-aligned img {
  float: left;
  margin-right: 20px; /* Add some space between the text and the image */
}

/* To clear the float and ensure proper rendering of the next block */
.features-container::after {
  content: "";
  display: table;
  clear: both;
}

/* Adjust the alignment of the content within the block to ensure it's in the center */
.right-aligned .center-content, .left-aligned .center-content {
  text-align: center;
}