/* styles.scss */

/* Base colors */
$color-white: #FFFFFF;
$color-black: #000000;

/* Primary palette */
$color-background: #F8F9FA; /* Base background color */
$color-surface: #FFFFFF; /* For cards, sheets, and surfaces */
$color-divider: #DEE2E6; /* For dividing lines and borders */
$color-placeholder: #CED4DA; /* Placeholder text or inactive elements */
$color-text-primary: #212529; /* Primary text color */
$color-text-secondary: #495057; /* Secondary text color, less emphasis */
$color-text-hint: #6C757D; /* For hints or help text, least emphasis */
$color-border: #363737; /* For borders and separators */
$color-shadow: #ADB5BD; /* For shadows and overlays */

/* Interactive colors */
$color-link: #007BFF; /* For hyperlinks */
$color-link-hover: #0056B3; /* For hyperlink hover states */
$color-button-primary: #007BFF; /* For primary buttons */
$color-button-hover: #0056B3; /* For primary button hover states */

/* Feedback colors (for alerts and notifications) */
$color-success: #28A745; /* For success messages or icons */
$color-warning: #FFC107; /* For warnings or caution icons */
$color-error: #DC3545; /* For error messages or icons */
$color-info: #17A2B8; /* For informational messages or icons */
