@import '../partials/variables';

.feature-block {
  display: flex;
  align-items: center;
  padding: 40px 60px;
  border-bottom: 2px solid $color-border;
  background-color: $color-background;
  margin: 0 auto; /* Center the feature block */
  height: auto; /* Changed to auto to accommodate content */
  flex-direction: row; /* Default flex direction for larger screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack elements for mobile */
    padding: 20px 30px; /* Reduced padding for smaller screens */
  }
}

.feature-content {
  flex: 1; /* This ensures the content occupies the defined max-width and not more */
  max-width: 50%; /* Limit the width of the content to half the parent div */
  padding-right: 20px;
  color: $color-text-primary;
  font-family: 'IBM Plex Sans', sans-serif;

  @media (max-width: 768px) {
    max-width: 100%; /* Allow content to use full width on small screens */
    padding-right: 0; /* Remove right padding on mobile */
    text-align: center; /* Center text for mobile */
  }
}

.feature-image {
  flex: 1; /* Adjust this value if you want to change the width of the image container */
  margin-left: auto; /* This pushes the image to the right */
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 20px auto; /* Center image on mobile */
    width: 80%; /* Reduce image size on mobile */
    max-width: 300px; /* Optional: Cap image size */
  }

  img {
    width: 100%; /* Ensure image is responsive */
    max-height: 350px;
    object-fit: cover;
  }
}

.right-aligned .feature-content,
.left-aligned .feature-image {
  @media (min-width: 769px) { /* Apply order for screens larger than 768px */
    order: 1;
  }
}

.right-aligned .feature-image,
.left-aligned .feature-content {
  @media (min-width: 769px) { /* Apply order for screens larger than 768px */
    text-align: right;
    order: 2;
  }
}

.buttons {
  margin-top: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.btn-primary, .btn-secondary {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

}

.btn-primary {
  background-color: #52616B;
  color: #F0F5F9;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #C9D6DF;
  color: #C9D6DF;
  margin-left: 10px;
}

.btn-secondary:hover {
  background-color: #52616B;
  color: #F0F5F9;
}
