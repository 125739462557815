@import '../partials/variables';

body {
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 100vh; /* Ensure the body takes up the full viewport height */
  }


/* ... your existing styles ... */

.slideshow-container {
  margin-bottom: 50px; /* Adjust based on desired space between slideshow and feature blocks */
}

.features-container {
  padding: 0 50px; /* Add 50px of padding to the left and right, adjust this value as per the required whitespace */
}
