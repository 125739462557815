@import '../partials/variables';

.type-gallery-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children elements */
  padding: 20px; /* Add padding for breathing room */
}

.type-gallery-header {
  font-size: 24px; /* Adjust as needed */
  margin-bottom: 20px; /* Space below the header */
}

.cards-container {
  width: 100%; /* Take full width */
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next row */
  justify-content: space-between; /* Space out the cards */
}

.card {
  flex: 0 1 calc(20% - 10px); /* Take up 20% of the container's width minus a little for margin. This will allow for 5 cards in a row. */
  margin: 5px; /* Add margin to space out the cards */
  background-color: #f5f5f5; /* Placeholder background color. Remove or adjust as needed */
  height: 200px; /* Adjust height as needed */
  position: relative; /* Set position for possible image or content positioning */
}

.placeholder {
  width: 100%; /* Take full width of the card */
  height: 100%; /* Take full height of the card */
  background-color: #e0e0e0; /* Different shade for placeholder. Adjust as needed */
}


.type-gallery-header {
    font-size: 24px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #ffffff;
    border-bottom: 2px solid #52616B;
    margin-bottom: 20px;
    padding-bottom: 10px;
    display: block; /* Ensure it doesn't interfere with grid items */
}
