/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
body {
  background-color: #F8F9FA;
  /* Default margin for small screens */
  margin-inline: 10px; /* Very thin margin for small screens */
  /* Medium screens (>= 768px) */
  /* Large screens (>= 1200px) */
}
@media (min-width: 768px) {
  body {
    margin-inline: 50px; /* Average margin for medium screens */
  }
}
@media (min-width: 2000px) {
  body {
    margin-inline: 300px; /* Very large margin for large screens */
  }
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.feature-block {
  display: flex;
  align-items: center;
  padding: 40px 60px;
  border-bottom: 2px solid #363737;
  background-color: #F8F9FA;
  margin: 0 auto; /* Center the feature block */
  height: auto; /* Changed to auto to accommodate content */
  flex-direction: row; /* Default flex direction for larger screens */
}
@media (max-width: 768px) {
  .feature-block {
    flex-direction: column; /* Stack elements for mobile */
    padding: 20px 30px; /* Reduced padding for smaller screens */
  }
}

.feature-content {
  flex: 1; /* This ensures the content occupies the defined max-width and not more */
  max-width: 50%; /* Limit the width of the content to half the parent div */
  padding-right: 20px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
}
@media (max-width: 768px) {
  .feature-content {
    max-width: 100%; /* Allow content to use full width on small screens */
    padding-right: 0; /* Remove right padding on mobile */
    text-align: center; /* Center text for mobile */
  }
}

.feature-image {
  flex: 1; /* Adjust this value if you want to change the width of the image container */
  margin-left: auto; /* This pushes the image to the right */
  margin-right: 20px;
}
@media (max-width: 768px) {
  .feature-image {
    margin: 20px auto; /* Center image on mobile */
    width: 80%; /* Reduce image size on mobile */
    max-width: 300px; /* Optional: Cap image size */
  }
}
.feature-image img {
  width: 100%; /* Ensure image is responsive */
  max-height: 350px;
  object-fit: cover;
}

@media (min-width: 769px) {
  .right-aligned .feature-content,
  .left-aligned .feature-image { /* Apply order for screens larger than 768px */
    order: 1;
  }
}

@media (min-width: 769px) {
  .right-aligned .feature-image,
  .left-aligned .feature-content { /* Apply order for screens larger than 768px */
    text-align: right;
    order: 2;
  }
}

.buttons {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.btn-primary, .btn-secondary {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.btn-primary {
  background-color: #52616B;
  color: #F0F5F9;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #C9D6DF;
  color: #C9D6DF;
  margin-left: 10px;
}

.btn-secondary:hover {
  background-color: #52616B;
  color: #F0F5F9;
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.header {
  background-color: #F8F9FA;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #52616B;
  margin-bottom: 5%;
  position: relative;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: #F0F5F9;
  margin-right: auto;
  min-width: 150px;
}

.company-title {
  color: #212529;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.company-title:hover {
  color: #C9D6DF;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  flex-wrap: wrap;
}

.navigation li {
  flex: 1 1 auto;
}

.navigation li a {
  text-decoration: none;
  color: #212529;
  font-weight: bold;
  font-size: 1.4rem;
  white-space: nowrap;
}

.navigation li a:hover {
  color: #C9D6DF;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
}
.hamburger span {
  display: block;
  height: 2px;
  width: 100%;
  background: #212529;
}

/* Media query for tablets and mobile devices */
@media (max-width: 840px) {
  .header {
    padding: 10px 20px;
  }
  .logo {
    font-size: 1.5rem;
  }
  .navigation {
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(248, 249, 250, 0.95);
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;
    transition: transform 0.3s ease, visibility 0s 0.3s, opacity 0.3s ease;
  }
  .navigation.visible {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.3s ease, visibility 0s, opacity 0.3s ease;
  }
  .hamburger {
    display: flex;
  }
  .navigation ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
    text-align: right;
  }
  .navigation li a {
    width: 100%;
    padding: 10px 20px;
    text-align: right;
  }
}
/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
  background: #fff;
  max-width: 100%; /* Allow the card to fill the flex container */
  font-family: "IBM Plex Sans", sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.image-container {
  width: 100%; /* this will take the full width of the parent element */
  padding-top: 75%; /* 3/4 = 75% aspect ratio for height */
  position: relative; /* allows absolute positioning inside */
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* this ensures the image covers the area without distorting */
}

.product-price {
  font-size: 1.2em;
  color: #333;
  margin-right: 16px; /* Added space between price and quantity box */
}

.quantity-box {
  padding: 2px 4px; /* Reduced padding to decrease height */
  font-size: 0.9em; /* Optional: Decrease font size if the input looks too large */
  line-height: 1.2; /* Optional: Adjust line height for better visual alignment */
  height: auto;
  display: flex;
  box-sizing: border-box; /* Include padding in the height calculation */
}

/* Modify the product-stats-wrapper for less vertical spacing */
.product-stats-wrapper {
  display: flex;
  justify-content: space-between; /* Align items to the start */
  align-items: center; /* Center items vertically */
  padding: 0; /* Keep padding to 0 */
  margin-top: 5px; /* Reduce margin-top to bring elements closer */
  gap: 8px; /* Provide some horizontal spacing between elements */
}

.card-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px; /* Reduced margin on top */
  flex-wrap: wrap;
}

.add-to-cart,
.view-btn {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  flex: 1 1 auto; /* Allows the buttons to grow and shrink as needed */
  flex-basis: calc(50% - 4px); /* Adjusted for the gap */
  flex-grow: 1; /* Allow buttons to grow to fill the space */
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.slideshow-container {
  max-width: 60%; /* Reduce the width for more whitespace on the sides */
  margin: 0 auto; /* This will center the slideshow horizontally */
}

.slick-slide img {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.slick-dots li button:before {
  color: #fff; /* Change color to white or another contrasting color */
  font-size: 10px; /* Increase the size of the dots */
  border: 2px solid #aaa; /* Add a border to make them stand out more */
  border-radius: 50%; /* Make the dots perfectly circular */
  opacity: 0.8; /* You can adjust this for desired visibility */
}

/* For the active dot */
.slick-dots li.slick-active button:before {
  color: #333; /* Dark color for the active dot for contrast */
  border-color: #333; /* Dark border for the active dot */
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.TitleCard {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content vertically and horizontally */
  background-color: #F0F5F9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  height: 350px;
  position: relative;
  margin: 10px; /* To ensure spacing between cards */
}

.TitleCard:hover {
  transform: scale(1.05);
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 7px 12px;
  border-radius: 6px;
  color: #1E2022;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.type-gallery-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children elements */
  padding: 20px; /* Add padding for breathing room */
}

.type-gallery-header {
  font-size: 24px; /* Adjust as needed */
  margin-bottom: 20px; /* Space below the header */
}

.cards-container {
  width: 100%; /* Take full width */
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next row */
  justify-content: space-between; /* Space out the cards */
}

.card {
  flex: 0 1 calc(20% - 10px); /* Take up 20% of the container's width minus a little for margin. This will allow for 5 cards in a row. */
  margin: 5px; /* Add margin to space out the cards */
  background-color: #f5f5f5; /* Placeholder background color. Remove or adjust as needed */
  height: 200px; /* Adjust height as needed */
  position: relative; /* Set position for possible image or content positioning */
}

.placeholder {
  width: 100%; /* Take full width of the card */
  height: 100%; /* Take full height of the card */
  background-color: #e0e0e0; /* Different shade for placeholder. Adjust as needed */
}

.type-gallery-header {
  font-size: 24px;
  font-family: "IBM Plex Sans", sans-serif;
  color: #ffffff;
  border-bottom: 2px solid #52616B;
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: block; /* Ensure it doesn't interfere with grid items */
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.type-motorcycle-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.type-motorcycle-header {
  text-align: center;
  margin-bottom: 2em;
  color: #212529;
  font-size: 2em;
}

.cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.card-wrapper {
  transition: 0.3s;
  flex: 0 0 calc((100% - 60px) / 3);
  min-width: calc((100% - 60px) / 3);
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .card-wrapper {
    flex: 0 0 calc((100% - 40px) / 2);
    min-width: calc((100% - 40px) / 2);
  }
}
@media (max-width: 800px) {
  .card-wrapper {
    flex: 0 0 calc(100% - 20px);
    min-width: calc(100% - 20px);
  }
}
/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.about-container {
  font-family: "IBM Plex Sans", sans-serif;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #FAFAFA;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.about-container h1 {
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: color-text-primary;
}

.about-container p {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: color-text-primary;
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.gallery-cta {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px 0 0 0; /* Adjusted padding for more vertical space */
}

.gallery-cta h1 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: color-text-primary;
  margin-bottom: 15px;
  margin-top: 0; /* Remove the default margin at the top */
}

.gallery-cta p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: color-text-primary;
  max-width: 800px;
  margin: 0 auto; /* Center the text if it's less than the max-width */
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 80px 0;
}

.category-box {
  background-color: #F0F5F9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  height: 350px; /* Increased height for rectangular images */
  position: relative;
}

.category-box:hover {
  transform: scale(1.05);
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #1E2022;
  height: 100%;
}

.category-image {
  width: 100%;
  height: 100%; /* Ensure image covers entire box */
  object-fit: cover;
}

.category-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* Lighter background for better readability */
  padding: 7px 12px; /* Slightly increased padding */
  border-radius: 6px;
  color: #1E2022;
  font-size: 20px; /* Larger font size for older audience */
  font-family: "Roboto", sans-serif; /* Using Arial for a clean, sans-serif look */
  font-weight: 500; /* Bold font weight for better readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Text shadow for additional readability */
}

.gallery-learn-more {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px 0; /* Adjusted padding for more vertical space */
}

.gallery-learn-more h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: color-text-primary;
  margin-bottom: 15px;
  margin-top: 0; /* Remove the default margin at the top */
}

.gallery-learn-more p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: color-text-primary;
  max-width: 800px;
  margin: 0 auto; /* Center the text if it's less than the max-width */
}

.right-aligned img {
  float: right;
  margin-left: 20px; /* Add some space between the text and the image */
}

/* For left-aligned images */
.left-aligned img {
  float: left;
  margin-right: 20px; /* Add some space between the text and the image */
}

/* To clear the float and ensure proper rendering of the next block */
.features-container::after {
  content: "";
  display: table;
  clear: both;
}

/* Adjust the alignment of the content within the block to ensure it's in the center */
.right-aligned .center-content, .left-aligned .center-content {
  text-align: center;
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
body {
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Ensure the body takes up the full viewport height */
}

/* ... your existing styles ... */
.slideshow-container {
  margin-bottom: 50px; /* Adjust based on desired space between slideshow and feature blocks */
}

.features-container {
  padding: 0 50px; /* Add 50px of padding to the left and right, adjust this value as per the required whitespace */
}

/* styles.scss */
/* Base colors */
/* Primary palette */
/* Base background color */
/* For cards, sheets, and surfaces */
/* For dividing lines and borders */
/* Placeholder text or inactive elements */
/* Primary text color */
/* Secondary text color, less emphasis */
/* For hints or help text, least emphasis */
/* For borders and separators */
/* For shadows and overlays */
/* Interactive colors */
/* For hyperlinks */
/* For hyperlink hover states */
/* For primary buttons */
/* For primary button hover states */
/* Feedback colors (for alerts and notifications) */
/* For success messages or icons */
/* For warnings or caution icons */
/* For error messages or icons */
/* For informational messages or icons */
.motorcycle-cta {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px 0 0 0; /* Adjusted padding for more vertical space */
}

.motorcycle-cta h1 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: color-text-primary;
  margin-bottom: 15px;
  margin-top: 0; /* Remove the default margin at the top */
}

.motorcycle-cta p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: color-text-primary;
  max-width: 800px;
  margin: 0 auto; /* Center the text if it's less than the max-width */
}

.motorcycle-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 80px 0;
}

.category-box {
  background-color: #F0F5F9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  height: 350px; /* Increased height for rectangular images */
  position: relative;
}

.category-box:hover {
  transform: scale(1.05);
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #1E2022;
  height: 100%;
}

.category-image {
  width: 100%;
  height: 100%; /* Ensure image covers entire box */
  object-fit: cover;
}

.category-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* Lighter background for better readability */
  padding: 7px 12px; /* Slightly increased padding */
  border-radius: 6px;
  color: #1E2022;
  font-size: 20px; /* Larger font size for older audience */
  font-family: "Roboto", sans-serif; /* Using Arial for a clean, sans-serif look */
  font-weight: 500; /* Bold font weight for better readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Text shadow for additional readability */
}

.motorcycle-learn-more {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px 0; /* Adjusted padding for more vertical space */
}

.motorcycle-learn-more h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: color-text-primary;
  margin-bottom: 15px;
  margin-top: 0; /* Remove the default margin at the top */
}

.motorcycle-learn-more p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: color-text-primary;
  max-width: 800px;
  margin: 0 auto; /* Center the text if it's less than the max-width */
}

.right-aligned img {
  float: right;
  margin-left: 20px; /* Add some space between the text and the image */
}

/* For left-aligned images */
.left-aligned img {
  float: left;
  margin-right: 20px; /* Add some space between the text and the image */
}

/* To clear the float and ensure proper rendering of the next block */
.features-container::after {
  content: "";
  display: table;
  clear: both;
}

/* Adjust the alignment of the content within the block to ensure it's in the center */
.right-aligned .center-content, .left-aligned .center-content {
  text-align: center;
}

