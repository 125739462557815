@import '../partials/variables';

.header {
  background-color: $color-background;
  color: $color-text-primary;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #52616B;
  margin-bottom: 5%;
  position: relative;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: #F0F5F9;
  margin-right: auto;
  min-width: 150px; // Prevent the logo from getting too small
}

.company-title {
  color: $color-text-primary;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.company-title:hover {
  color: #C9D6DF;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  flex-wrap: wrap; // Allow items to wrap if necessary
}

.navigation li {
  flex: 1 1 auto; // Allow flex items to grow and shrink
}

.navigation li a {
  text-decoration: none;
  color: $color-text-primary;
  font-weight: bold;
  font-size: 1.4rem;
  white-space: nowrap;
}

.navigation li a:hover {
  color: #C9D6DF;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;

  span {
    display: block;
    height: 2px;
    width: 100%;
    background: $color-text-primary;
  }
}

/* Media query for tablets and mobile devices */
@media (max-width: 840px) {
  .header {
    padding: 10px 20px;
  }

  .logo {
    font-size: 1.5rem;
  }

  .navigation {
    visibility: hidden; // Start off invisible
    opacity: 0; // Start fully transparent
    transform: translateX(100%); // Slide off to the right
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba($color-background, 0.95); // Semi-transparent background
    flex-direction: column;
    align-items: flex-end; // Align links to the end
    z-index: 1000; // High z-index to overlay content
    transition: transform 0.3s ease, visibility 0s 0.3s, opacity 0.3s ease; // Apply the visibility delay only when hiding the menu
  }

  .navigation.visible {
    visibility: visible; // Make the navigation visible
    opacity: 1; // Full opacity when visible
    transform: translateX(0); // Slide in from right to left
    transition: transform 0.3s ease, visibility 0s, opacity 0.3s ease; // No delay when showing the menu
  }

  .hamburger {
    display: flex;
  }

  .navigation ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
    text-align: right; // Align text to the right
  }

  .navigation li a {
    width: 100%;
    padding: 10px 20px;
    text-align: right; // Align text to the right on mobile
  }
}
