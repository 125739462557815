@import '../partials/variables';

body {
    background-color: $color-background;

    /* Default margin for small screens */
    margin-inline: 10px; /* Very thin margin for small screens */

    /* Medium screens (>= 768px) */
    @media (min-width: 768px) {
        margin-inline: 50px; /* Average margin for medium screens */
    }

    /* Large screens (>= 1200px) */
    @media (min-width: 2000px) {
        margin-inline: 300px; /* Very large margin for large screens */
    }
}
